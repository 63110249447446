import './App.css';
import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaRegEnvelope } from "react-icons/fa";
import { db } from "./firebase_config";
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [emailAddress, setEmailAddress] = useState("");
  
  const postDataToFirestore = async () => {
    try {
      const data = {
        email: emailAddress,
        timestamp: new Date(), // Optional: Add a timestamp
      };
  
      // Reference the "users" collection in Firestore
      await addDoc(collection(db, "waiting"), data);
      setEmailAddress("");
      toast.success('Thank you for subscribing!, Centria will be in touch as soon as possible', {
        position: "top-right",
        autoClose: 3000, // Auto close after 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light", // Use "dark" for a dark theme
        className: "custom-toast"
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  // Function to handle input change
  const handleInputChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleClick = (message) => {
    postDataToFirestore()
  };
  return (
    <div className="background">
      <div className="container">
        <header className="header ">
          <div className="left-section">
          <img
            src= "./logo.svg" // Replace with your icon source
            alt="icon"
            className="icon"
          />
          <h2 className='brownText'>Centria</h2>
        </div>
        <h2 className="right-section brownText">
            Coming soon!!!
        </h2>
        </header>
        <main>
          <div className='left-section left-text space1'>
            <h1>Your Complete Solution for 
              <span> Construction Management</span>
            </h1>
          </div>
          <ToastContainer />
          <div  className='left-section left-text space2 info'>
          Streamline your projects, collaborate with your team, and manage tasks
              efficiently. With Centria, simplify your construction management and achieve your project success.
          </div>
          <div  className='left-section left-text space3 info'>
              Be among the first to experience the power of Centria’s seamless
              construction management. Sign up now to stay updated!
          </div> 
          <div className="left-section left-text space4">
            <form className="subscribe-form">
            <div className="subscribe-container">
                <div className="input-wrapper">
                  <FaRegEnvelope />
                  <input
                    type="email"
                    className="input-field"
                    value={emailAddress}
                    onChange={handleInputChange}
                    placeholder="Enter your email"
                  />
                </div>
                <button className="subscribe-button" type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("Hello from the button!");
                  }}
                >Subscribe</button>
              </div>
            </form>
          </div>
          <div className="social-links left-section space5">
            <a className='social-icon' href="#facebook"><FaFacebook /></a>
            <a className='social-icon'  href="#twitter"><FaTwitter /></a>
            <a className='social-icon'  href="#instagram"><FaInstagram /></a>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
