// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8iVo5AfmK61n0_pVuXia-0o_gijv6g88",
  authDomain: "project-management-f2529.firebaseapp.com",
  projectId: "project-management-f2529",
  storageBucket: "project-management-f2529.appspot.com",
  messagingSenderId: "690103417304",
  appId: "1:690103417304:web:7a83afd5a6436677b61aad",
  measurementId: "G-X9Z5BWFBYZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app); // Firestore Database